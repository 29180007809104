import {FC, Fragment, useEffect, useMemo, useRef, useState} from "react";
import {GetEventPlayersCall} from "../../../apis/player";
import {BookmakerData} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import {ENABLED_BOOKMAKERS} from "../../../constants/PlayerConstants";

interface TooltipPlayerOddsProps {
    children: any
    eventId: number
    playerId: string
    marketId: string
    signId: string
    sbv: string
}

const TooltipPlayerOdds: FC<TooltipPlayerOddsProps> = ({children, eventId, playerId, marketId, signId, sbv}) => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);

    const [odds, setOdds] = useState<any>({});
    const [callTimeout, setCallTimeout] = useState<any>(null);
    const [tooltipTimeout, setTooltipTimeout] = useState<any>(null);
    const [mouseOver, setMouseOver] = useState<boolean>(false);

    const eventBookmakers: number[] = useMemo(() => {
        return Object.entries(bookmakers)
                .filter(([id, bookmaker]) => ENABLED_BOOKMAKERS.has(parseInt(id)) && !bookmaker.isComparison)
                .map(([b]) => parseInt(b))
    }, [bookmakers])

    const tipRef = useRef<HTMLDivElement>(null);
    let scroll: number = document.getElementById('playerTable')?.scrollTop || 0

    useEffect(() => {
        if (!mouseOver) {
            hideTooltip();
        }
    }, [mouseOver])


    const getOdds = () => {
        scroll = document.getElementById('playerTable')?.scrollTop || 0
        GetEventPlayersCall(eventId).then((result) => {
            setOdds(result)
        }, () => {
            setOdds({})
        })
    }

    const showTooltip = () => {
        if (tipRef.current) {
            tipRef.current.style.display = 'block';
            tipRef.current.style.position = 'fixed';
            tipRef.current.style.marginLeft = "50px";
            tipRef.current.style.marginTop = `${-scroll * 2}px`
            tipRef.current.style.zIndex = '9999';
        }
    }

    const hideTooltip = () => {
        if (tipRef.current) {
            tipRef.current.style.display = 'none';
            tipRef.current.style.marginLeft = "0px";
            tipRef.current.style.zIndex = '0';
        }
    }

    return <Fragment>
        <div
                className="flex items-center"
        >
            <div
                    className={"absolute whitespace-no-wrap border-2 bg-eventDetail-primary text-white px-4 py-2 rounded-xl flex items-center transition-none "}
                    style={{display: 'none'}}
                    ref={tipRef}
            >
                <table className='w-full border-monitor-primary border-spacing-1'>
                    <tbody>
                    {eventBookmakers.map(bookmakerId => {
                        const bookmaker = bookmakers[bookmakerId]

                        const backOdd = odds[bookmaker.id]?.players[playerId]?.markets[marketId.toString()]?.[sbv]?.[signId]?.odd
                        return <Fragment key={bookmakerId}>
                            <tr className='border-4 border-monitor-primary mb-2'>
                                <td className={`text-left font-bold border-eventDetail-primary ${bookmakers[bookmakerId].id === 2 ? 'bg-monitor-betfair' : 'bg-eventDetail-bookmaker'} px-2 pr-4`}>
                                    {bookmakers[bookmakerId].name}
                                </td>
                                <td className={`text-center border-eventDetail-primary ${bookmakers[bookmakerId].id === 2 ? 'bg-monitor-betfair' : 'bg-eventDetail-bookmaker'} px-2 pl-4`}>
                                    <b>{backOdd > 1 ? backOdd : '-'}</b>
                                </td>
                            </tr>
                        </Fragment>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="text-left"
             onMouseEnter={() => {
                 setMouseOver(true)
                 setCallTimeout(setTimeout(getOdds, 400))
                 setTooltipTimeout(setTimeout(showTooltip, 500))
             }}
             onMouseLeave={() => {
                 setMouseOver(false)
                 hideTooltip()
                 if (tooltipTimeout) {
                     clearTimeout(tooltipTimeout)
                     setTooltipTimeout(null)
                 }
                 if (callTimeout) {
                     clearTimeout(callTimeout)
                     setCallTimeout(null)
                 }
             }}
        >{children}</div>
    </Fragment>
}

export default TooltipPlayerOdds
